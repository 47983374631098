<template>
  <v-row>
    <v-col cols="12" v-if="loading">
      <v-skeleton-loader class="rounded-md" transition="fade-transition" type="list-item-three-line" />
    </v-col>
    <v-col cols="12" v-else>
      <!-- <v-card class="mb-6" flat>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Notificaciones</v-toolbar-title>
          </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-skeleton-loader class="mt-n3" v-if="loading" transition="fade-transition" type="list-item-three-line" />
          <v-list class="transparent py-2" v-else>
            <v-list-item class="mx-4" two-line>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold">Recibir notificaciones consolidadas</v-list-item-title>
                <v-list-item-subtitle class="mt-1 body-1">De forma predeterminada, las notificaciones se envían al dueño del espacio. Para notificar a más usuarios, configure los correos a continuación.</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch class="my-2" v-model="showNotification" :width="32" :height="18" color="success" hide-details :ripple="false" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card> v-if="showNotification" -->
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_notification'])" :isDetail="true" />
      <div v-else>
        <v-card class="mb-6" flat>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Certificado digital</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items class="pr-5">
              <v-row align="center" justify="center" no-gutters>
                <v-switch class="my-2" v-model="showCertificateNotification" :width="32" :height="18" color="success" hide-details :ripple="false" />
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <template v-if="showCertificateNotification">
            <v-divider />
            <v-card-text class="pa-0">
              <v-list class="transparent py-2" :key="isReset">
                <v-list-item class="mx-4" two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="mt-1 body-1">
                      Selecciona a qué usuarios se les enviarán notificaciones sobre el vencimiento del certificado digital
                      <v-col class="px-0 mt-1" cols="4">
                        <v-autocomplete
                          v-model="certificateNotifications.members"
                          hide-details
                          single-line
                          color="primary"
                          dense
                          outlined
                          :items="membersList"
                          multiple
                          :chips="false"
                          item-text="email"
                          item-value="id"
                          :label="(certificateNotifications.members || []).length ? '' : 'Selecciona a los usuarios'"
                          :prefix="(certificateNotifications.members || []).length >= 1 ? `${(certificateNotifications.members || []).length === 1 ? `${(certificateNotifications.members || []).length} seleccionado` : `${(certificateNotifications.members || []).length} seleccionados`}` : ''"
                          no-data-text="No existen miembros para mostrar"
                        >
                          <template v-slot:selection="{ props }">
                            <span v-if="props" style=""></span>
                          </template>
                        </v-autocomplete>
                        <v-row no-gutters>
                          <v-col class="pa-0 mt-2">
                            <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in chipMembers(certificateNotifications.members)" :key="`${m}${i}`" close @click:close="memberRemove(m, index)" color="lightBlue" small label close-icon="mdi-close">
                              {{m.email }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <span class="d-block">Se enviará una notificación, antes de los días seleccionados al vencimiento del certificado digital</span>
                      <v-row :class="[`ml-n1 ${ (chipMembers(certificateNotifications.members) || []).length ? '' : 'mt-1'}`]" no-gutters>
                        <v-col cols="1" class="pa-0" v-for="(day, d) in daysList" :key="d" style="min-width: 90px; !important">
                          <v-checkbox class="ma-0 vcheckbox" v-model="rules" :value="day" row :label="`${day} día${day > 1 ? 's' : ''}`" dense hide-details />
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-switch class="my-2" v-model="certificateNotifications.is_active" :width="32" :height="18" color="success" hide-details :ripple="false" />
                  </v-list-item-action> -->
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-4">
              <v-spacer/>
              <v-btn @click="reset" outlined :ripple="false">Cancelar</v-btn>
              <v-btn color="primary" @click="!(Object.keys(currentNotification('certificate')) || []).length ? saveNotifications() : updateNotifications()" :ripple="false" :loading="loadingcertificateNotifications" :disabled="(!((certificateNotifications || {}).members || []).length) || !$helpers.hasSomePermission(['change_notification', 'add_notification'])">Guardar</v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  components: { PermissionDenied},
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    rules: [],
    isReset: 0,
    loading: false,
    loadingcertificateNotifications: false,
    showCertificateNotification: false,
    showNotification: false,
    daysList: [30, 10, 5, 2, 1],
    certificateNotifications: {
      model: 'certificate',
      title: 'alarm certificates',
      message: '',
      kind: 'email',
      is_active: false,
      rule: 'menor;dias;5',
      members: []
    },
    notificationsList: [],
    backupCertificateNotifications: {}
  }),
  computed: {
    ...mapState({
      membersList: state => state.accounts.membersList
    }),
    chipMembers () {
      return (val) => {
        return this.membersList?.filter((item) => val?.some((elm) => elm === item.id)) ?? []
      }
    },
    currentNotification () {
      return (val) => {
        return this.notificationsList?.find((item) => item.model === val) ?? {}
      }
    }
  },
  watch: {
    'certificateNotifications.is_active' (val) {
      this.showCertificateNotification = val
    }
  },
  created () {
    if (this.$helpers.hasSomePermission(['view_notification'])) this.getList()
    if (this.$helpers.hasPermission(['view_member'])) {
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
    }
  },
  methods: {
    getList () {
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'notifications'
      })
      .then((resp) => {
        /* eslint-disable */
        this.notificationsList = [...resp.data.results]
        //this.showNotification = this.notificationsList.some(({is_active}) => is_active)
        // if (!this.showNotification) {
        //   this.setCertificate()
        //   // this.rules = [30, 10, 5, 2, 1]
        // } else {
          this.setCertificate()    
        // }
      })
      .finally(() => {
        this.loading = false
      })
    },
    setCertificate () {
      this.certificateNotifications = {...this.currentNotification('certificate')}
      let currentRule = this.certificateNotifications?.rule?.match(/\[(.*?)]/)[1].split(',')
      this.rules = currentRule?.map((item) => parseInt(item)) ?? [30, 10, 5, 2, 1]
      this.backupCertificateNotifications = {...this.certificateNotifications}
      this.certificateNotifications.is_active = !!Object.keys(this.certificateNotifications ?? {})?.length ? this.certificateNotifications.is_active : false
    },
    memberRemove (item, parentIndex) {
      const index = this.notifications[parentIndex].members.indexOf(item)
      if (index >= 0) this.notifications[parentIndex].members.splice(index, 1)
    },
    updateNotifications () {
      this.loadingcertificateNotifications = true
      let payload = this.certificateNotifications
      payload.rule = `menor;dias;[${this.rules}]`
      payload.is_active = this.showCertificateNotification 
      payload.members = payload.members.map((elm) => {
        return {
          member: elm?.id ?? elm
        }
      })
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'notifications',
        payload: payload,
        id: payload.id
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info('Las notificaciones de la organización se han actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.isReset += 1
        this.loadingcertificateNotifications = false
      })
    },
    saveNotifications () {
      this.loadingcertificateNotifications = true
      let payload = this.certificateNotifications
      payload.rule = `menor;dias;[${this.rules}]`

      payload.members = payload.members.map((elm) => {
        return {
          member: elm?.id ?? elm
        }
      })
      payload = {
        ...payload,
        model: 'certificate',
        kind: 'email'
      }
      this.$store.dispatch('accounts/CREATE', {
        resource: 'notifications',
        payload: payload,
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info('Las notificaciones de la organización se han actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.isReset += 1
        this.loadingcertificateNotifications = false
      })
    },
    reset () {
      if (!!Object.keys(this.currentNotification('certificate'))?.length) {
        this.certificateNotifications = {...this.backupCertificateNotifications}
        let currentRule = this.certificateNotifications.rule.match(/\[(.*?)]/)[1].split(',')
        this.rules = currentRule.map((item) => parseInt(item))
      } else {
        this.certificateNotifications = {
          model: 'certificate',
          title: 'alarm certificates',
          message: '',
          kind: 'email',
          is_active: false,
          rule: 'menor;dias;5',
          members: []
        }
        this.rules = [30, 10, 5, 2, 1]
      }
    }
  }
}
</script>